import type { ClassValue } from 'clsx'
import clsx from 'clsx'
import { extendTailwindMerge } from 'tailwind-merge'

export const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'text-alignment': [
        'text-left',
        'text-center',
        'text-right',
        'txt-inherit'
      ],
      'font-size': [
        'text-body-h1',
        'text-body-h2',
        'text-body-h3',
        'text-body-h4',
        'text-body-h5',
        'text-body-h6',
        'text-brand-h1',
        'text-brand-h2',
        'text-brand-h3',
        'text-brand-h4',
        'text-brand-h5',
        'text-brand-h6',
        'text-landing-subtitle',
        'text-landing-description',
        'text-body1',
        'text-body2',
        'text-input',
        'text-label',
        'text-subtitle1',
        'text-subtitle2',
        'text-mention'
      ]
    }
  }
})

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
